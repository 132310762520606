<template>
  <v-container>
  <!-- <div> -->
    <!-- <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 ma-3 text-center"> -->
    <v-row>
      <v-col class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 ma-n2 text-center">
        Mensajes recibidos
      </v-col>
    </v-row>
    <!-- </div> -->
    <template v-if="this.tipomensajes == 'Aux' && this.$store.state.usuarioAux != ''">
      <!-- <div class="red--text text--darken-4 text-caption text-sm-caption text-md-subtitle-2 text-lg-subtitle-2 text-xl-subtitle-2 mt-subtitle-2 font-weight-bold text-center mt-n3"> -->
      <v-row>
        <v-col class="red--text text--darken-4 text-caption text-sm-caption text-md-subtitle-2 text-lg-subtitle-2 text-xl-subtitle-2 mt-subtitle-2 font-weight-bold text-center mt-n3">
          {{this.$store.state.nombreUsuarioAux}}
        </v-col>
      </v-row>
      <!-- </div> -->
    </template>

    <v-form :disabled="loading">
      <v-row dense>
        <v-col>
          <v-expansion-panels v-model="panelFiltroStatus">
            <v-expansion-panel readonly>
              <v-expansion-panel-header>

                <v-overflow-btn
                  label="Lectura"
                  v-model="filtroLectura"
                  dense
                  outlined
                  hide-details
                  :items="itemsLectura"
                  class="ml-n4 mt-n3 mb-n3 pl-n10"
                  style="max-width:220px"
                  @change="actualizarMensajes"
                >
                </v-overflow-btn>
                <!-- <v-btn 
                  class="text-caption ml-2 mr-2 mt-n3 mb-n3" 
                  style="max-width:20px"
                  @click="actualizarMensajes"
                >
                  Aplicar
                </v-btn> -->

                <template v-slot:actions>
                  <v-btn
                    icon
                    class="mr-n3 mt-n2 mb-n2"
                    @click="mostrarPanel"
                  >
                    <v-icon>mdi-arrow-down-bold-outline</v-icon>
                  </v-btn>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <v-row dense>
                    <v-col class="mb-n5" cols="6" sm="5" md="3" lg="3" xl="2">
                      <DateFind label="Desde" v-model="filtroDesdeFecha" @change="actualizarMensajes"></DateFind>
                    </v-col>

                    <v-col class="mb-n5" cols="6" sm="5" md="3" lg="3" xl="2">
                      <DateFind label="Hasta" v-model="filtroHastaFecha" @change="actualizarMensajes"></DateFind>
                    </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="auto">
                    <PerFind class="mb-n5" ref="perFind" label="De" v-model="filtroPerOri" @change="actualizarMensajes"></PerFind>
                  </v-col>
                </v-row> 

                <v-row dense>
                  <v-col cols="12" sm="9" md="5" lg="4" xl="3">
                    <MsjCatFind class="mb-n5" ref="msjCatFind" label="Categoría" v-model="filtroMsjCat" @change="actualizarMensajes"></MsjCatFind>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="12" md="9" lg="7" xl="6">
                    <v-text-field
                      class="mb-n7"
                      outlined
                      dense
                      v-model="filtroAsunto"
                      label="Asunto"
                      ref="asuntoCtrl"
                      @change="actualizarMensajes"
                    ></v-text-field>
                  </v-col>
                </v-row>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="mensajes"
          :footer-props="{itemsPerPageOptions: [15, 30, 50, 100],disablePagination:loading, disableItemsPerPage: loading}"
          :options.sync="options"
          :server-items-length="totalMensajes"
          :loading="loading"
          dense
          style="overflow: scroll"
          mobile-breakpoint="0"
          class="elevation-1"
          @click:row="seleccion"
          show-expand
          single-expand
          item-key="MsjId"
          :item-class="GetItemClass"
        >
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.MsjLeido="{ item }">
            <v-simple-checkbox v-model="item.MsjLeido" disabled></v-simple-checkbox>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td colspan="3">
            </td>
            <td>
              <!-- <table v-for="(elemMsjPer, indexMsjPer) in item.MsjPer" :key="indexMsjPer"> -->
              <table v-for="(elemMsjPer) in item.MsjPer" :key="elemMsjPer.PerId">
                <tr>
                  <td>{{ elemMsjPer.PerNom }}</td>
                </tr>
              </table>
              <!-- <table v-for="(elemMsjPerCat, indexMsjPerCat) in item.MsjPerCat" :key="indexMsjPerCat"> -->
              <table v-for="(elemMsjPerCat) in item.MsjPerCat" :key="elemMsjPerCat.PerCatId">
                <tr>
                  <td>{{ elemMsjPerCat.PerCatNom }}</td>
                </tr>
              </table>
            </td>
            <td :colspan="headers.length - 4"></td>
          </template>      
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
  <!-- </div> -->
</template>

<script>
  import axios from "axios";
  // import { mapState, mapMutations, mapActions } from "vuex";
  import { mapState, mapMutations } from "vuex";
  import funcs from "../funcs";
  import storeRecibidos from '../store/modules/MensajesRecibidos.js'
  import DateFind from "../components/DateFind.vue";
  import MsjCatFind from "../components/MsjCatFind.vue";
  import PerFind from "../components/PerFind.vue";

  export default {
    components: {
      DateFind, MsjCatFind, PerFind
    },
    computed:{
        ...mapState(['empId', 'perId', 'perIdAux', 'urlRaiz']),
        // ...mapState('MensajesRecibidos', ['entidadActual'])
        // ...mapState('MensajesRecibidos', ['entidadActual','perIdReceptor','perIdLector'])
    },
    data () {
      return {
        expanded: [],
        itemsLectura: ['Sin leer', 'Leídos', 'Todos'],
        filtroLectura: 'Sin leer',
        filtroAsunto: '',
        filtroPerOri: [],
        filtroMsjCat: [],
        // desdeFecha: new Date().toISOString().substr(0, 10),
        filtroDesdeFecha: '',
        filtroHastaFecha: '',
        panelFiltroStatus: 1,
        perIdReceptor: 0,
        perIdLector: 0,
        totalMensajes: 0,
        mensajes: [],
        loading: true,
        dtOptionsLoaded: false,
        options: {itemsPerPage: 15},
        headers: [
          { text: 'Fecha', width: 150, divider: true, sortable: false, value: 'MsjFecFormatted' },
          { text: 'De:', width: 250, sortable: false, value: 'Per.PerNom' },
          { text: 'Asunto', width:300, sortable: false, value: 'MsjNom' },
          { text: 'Para', width: 250, class: 'col_para', sortable: false, value: 'MsjPara' },
          { text: 'Leido', width: 70, sortable: false, value: 'MsjLeido' },
          { text: 'Categoría', width: 225, sortable: false, value: 'MsjCatNom' },
          { text: 'Fecha lec.', width: 150, sortable: false, value: 'MsjLecFec' },
          { text: '', value: 'data-table-expand'}
        ],
        filtroLecturaOpt: [
          {label: 'Sin leer', value: false}, 
          {label: 'Leídos', value: true}, 
          {label:'Todos', value: null}
        ]
      }
    },
    props: {
      tipomensajes: { type: String },
      msjcat: { type: Object }
    },
    watch: {
      options: {
        handler () {
          // Evitamos la llamada a la API en la primera carga de las options
          if(!this.dtOptionsLoaded){
            this.dtOptionsLoaded = true;
            return;
          }
          this.getDataFromApi()
            .then(data => {
              this.mensajes = data.items;
              this.totalMensajes =  data.total;
            })
        },
        deep: true,
      },
      tipomensajes: {
        handler() {
          // alert('Handler tipo: ' + this.tipomensajes);
          if (this.tipomensajes == 'Aux') {
            this.perIdReceptor = this.perIdAux;
            this.perIdLector = this.perId;
          }
          else {
            this.perIdReceptor = this.perId;
            if (this.$store.state.esEmpleado) { 
              this.perIdLector = this.perIdLector = this.perId;
            }
            else {
              this.perIdLector = this.perIdAux;
            }
          }
          this.actualizarMensajes();
        }
      },
      msjcat: {
        handler() {
          // alert('Handler cat:' + this.msjcat.MsjCatNom);
          if (this.msjcat != null) {
            if (this.msjcat.MsjCatId != 0) {
              this.filtroMsjCat = this.msjcat;
            }
            else {
              this.filtroMsjCat = [];
            }
            this.actualizarMensajes();
          }
        }
      },
      loading: {
        handler() {
          if (this.loading && this.panelFiltroStatus === 0) {
            this.$refs.perFind.$refs.PerFindCtrl.isMenuActive = false;
            this.$refs.msjCatFind.$refs.MsjCatFindCtrl.isMenuActive = false;
          }
        },
        deep: true
      }
    },
    mounted () {
      // INICIO DE SISTEMA SIMPLE DE NOTIFICACIONES
      // Notification.requestPermission().then(function(result) {
      //   alert('Tiene permiso para notificaciones')
      // console.log(result);
      // });
      // FIN DE SISTEMA SIMPLE DE NOTIFICACIONES

      // this.spawnNotification('Saludos de tu compañero', 'LogoMartinMini.png', 'Hola compi')
      // this.spawnNotification('Saludos de tu compañero', '', 'Hola compi')

      this.actualizarMensajes();
      setInterval(this.actualizarMensajes, 900000);
    },
    // beforeRouteUpdate(to, from, next) {
    //   next();
    // },
    methods: {
      ...mapMutations('MensajesRecibidos', ['entidadActualSet', 'receptorPerIdSet', 'perIdLectorSet', 'msjIdSet']),
      seleccion(itm) {
        // this.entidadActualSet(itm);
        this.receptorPerIdSet(this.perIdReceptor);
        this.perIdLectorSet(this.perIdLector);
        this.msjIdSet(itm.MsjId);
        
        var filtroPerIdOri = null;
        if (this.filtroPerOri != null && this.filtroPerOri.PerId != null) {
          filtroPerIdOri = this.filtroPerOri.PerId;
        }
        var filtroMsjCatId = null;
        if (this.filtroMsjCat != null && this.filtroMsjCat.MsjCatId != null) {
          filtroMsjCatId = this.filtroMsjCat.MsjCatId;
        }
        storeRecibidos.state.filtros = {
            Lectura: this.filtroLecturaOpt.find(f => f.label == this.filtroLectura).value,
            DesdeFecha: new Date(this.filtroDesdeFecha),
            HastaFecha: new Date(this.filtroHastaFecha),
            PerIdOri: filtroPerIdOri,
            MsjCatId: filtroMsjCatId,
            Asunto: this.filtroAsunto,
        };
        
        this.$router.push('/recibir-mensajes-edicion')
      },
      // INICIO DE FUNCION MOSTRAR NOTIFICACION
      // spawnNotification(theBody,theIcon,theTitle) {
      //   var options = {
      //       body: theBody,
      //       icon: theIcon
      //   }
      //   var n = new Notification(theTitle, options);
      //   setTimeout(n.close.bind(n), 5000);
      // },
      // FIN DE FUNCION MOSTRAR NOTIFICACION
      mostrarPanel() {
        if (this.panelFiltroStatus == 1) {
          this.panelFiltroStatus = 0; // Deplegado
        }
        else {
          this.panelFiltroStatus = 1; // Contraido
        }
      },
      actualizarMensajes() {
        // alert('Valores al actualizar, Tipo: ' + this.tipomensajes + ', categoria: ' + this.msjcat.MsjCatNom);
        if (this.tipomensajes == 'Aux') {
          this.perIdReceptor = this.perIdAux;
          this.perIdLector = this.perId;
        }
        else {
          this.perIdReceptor = this.perId;
          if (this.$store.state.esEmpleado) { // Es empleado
            this.perIdLector = this.perIdLector = this.perId;
          }
          else {
            this.perIdLector = this.perIdAux;
          }
        }

        if (this.msjcat != null) {
          if (this.msjcat.MsjCatId != 0) {
            this.filtroMsjCat = this.msjcat;
          }
          else {
            this.filtroMsjCat = [];
          }
        }

        this.getDataFromApi()
        .then(data => {
          this.mensajes = data.items;
          this.totalMensajes =  data.total;

          // this.mensajes.forEach(element => {
          //   if (!element.MsjLeido) {
          //     this.spawnNotification(element.MsjNom, 'LogoMartinMini.png', 'Mensajes pendientes')
          //   }
          // });
        })
      },
      getDataFromApi () {
        this.loading = true
        // return new Promise((resolve, reject) => {
        return new Promise((resolve) => {
          const { sortBy, sortDesc } = this.options;

          // let items = this.getMensajes()

          // var CampoBusqueda = '';
          // var ValorBuscar = '';
          var NumRegsPag = this.options.itemsPerPage;
          var NumPag = this.options.page;
          
          var filtroPerIdOri = null;
          if (this.filtroPerOri != null && this.filtroPerOri.PerId != null) {
            filtroPerIdOri = this.filtroPerOri.PerId;
          }

          var filtroMsjCatId = null;
          if (this.filtroMsjCat != null && this.filtroMsjCat.MsjCatId != null) {
            filtroMsjCatId = this.filtroMsjCat.MsjCatId;
          }

          var controllerParameters = {
              Action: 'GET_MENSAJES_RECIBIDOS',
              FiltroLectura: this.filtroLectura,
              FiltroDesdeFecha: new Date(this.filtroDesdeFecha),
              FiltroHastaFecha: new Date(this.filtroHastaFecha),
              FiltroPerIdOri: filtroPerIdOri,
              FiltroMsjCatId: filtroMsjCatId,
              FiltroAsunto: this.filtroAsunto,
              NumRegsPag: NumRegsPag,
              NumPag: NumPag,
              EsEmpleado: this.$store.state.esEmpleado,
              PerId: this.perIdReceptor,
              PerIdAux: this.perIdLector 
          };       

          // axios({ method: "POST", "url": this.urlRaiz + "/api/mensajes", "data": JSON.stringify(controllerParameters), "headers": { "content-type": "application/json" } }).then(result => {
          var AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/msj", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
              let items = [];
              result.data.EntsList.forEach(element => {

                // var Fecha = new Date(element.MsjFec);

                var FechaStr = funcs.UtcDateTimeToStr(element.MsjFec, 'datetime');

                // var FechaStr = Fecha.getDate().toString().padStart(2, "0") + "/" + 
                //   (Fecha.getMonth() + 1).toString().padStart(2, "0") + "/" + 
                //   Fecha.getFullYear().toString() + " " + 
                //   Fecha.getHours().toString().padStart(2, "0") + ":" + 
                //   Fecha.getMinutes().toString().padStart(2, "0");

                var ParaStr = '';
                try {
                  if (element.MsjPer.length >= 1) {
                    ParaStr = element.MsjPer[0].PerNom;
                  }
                  else if (element.MsjPerCat.length >= 1) {
                    ParaStr = element.MsjPerCat[0].PerCatNom;
                  }
                  if ((element.MsjPer.length + element.MsjPerCat.length) > 1) {
                    ParaStr = ParaStr + ', . . . ';
                  }
                } catch{console.log('')}


                var MsjCatNomStr = '';
                try 
                {
                  if (element.MsjCatMsj.length >= 1) {
                    MsjCatNomStr = element.MsjCatMsj[0].MsjCatNom;
                  }

                } catch{console.log('')}


                // element.MsjPer.forEach(elemPer => {
                //     if (ParaStr.length > 0) {
                //       ParaStr = ParaStr + '; ';
                //     }
                //     ParaStr = ParaStr + elemPer.PerNom;
                // })

                // element.MsjPerCat.forEach(elemPerCat => {
                //     if (ParaStr.length > 0) {
                //       ParaStr = ParaStr + '; ';
                //     }
                // })

                var MsjLecFecStr = '';
                try
                {

                  if (element.MsjLec != null) {
                    var MsjLecFec = new Date(element.MsjLec.MsjLecFec);
                    MsjLecFecStr = MsjLecFec.getDate().toString().padStart(2, '0') + '/' + 
                      (MsjLecFec.getMonth() + 1).toString().padStart(2, '0') + '/' + 
                      MsjLecFec.getFullYear().toString() + ' ' + 
                      MsjLecFec.getHours().toString().padStart(2, '0') + ':' + 
                      MsjLecFec.getMinutes().toString().padStart(2, '0');
                  }
                }catch{console.log('')}

                var itemObject = {
                  Per: element.Per,
                  PerId: element.PerId,
                  MsjFecFormatted: FechaStr,
                  MsjNom: element.MsjNom,
                  MsjPara: ParaStr,
                  MsjLeido: element.MsjLeido,
                  MsjLecFec: MsjLecFecStr,
                  MsjCatNom: MsjCatNomStr,
                  MsjPer: element.MsjPer,
                  MsjPerCat: element.MsjPerCat,
                  MsjCatMsj: element.MsjCatMsj,
                  MsjTxt: element.MsjTxt,
                  EmpId: element.EmpId,
                  MsjId: element.MsjId,
                  MsjFec: element.MsjFec,
                  MsjFic: element.MsjFic
                };
                items.push(itemObject);
              })

              const total = result.data.EntsTotCount;

            if (sortBy.length === 1 && sortDesc.length === 1) {
              items = items.sort((a, b) => {
                const sortA = a[sortBy[0]];
                const sortB = b[sortBy[0]];

                if (sortDesc[0]) {
                  if (sortA < sortB) return 1;
                  if (sortA > sortB) return -1;
                  return 0;
                } else {
                  if (sortA < sortB) return -1;
                  if (sortA > sortB) return 1;
                  return 0;
                }
              })
            }

            // if (itemsPerPage > 0) {
            //   items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
            // }

            setTimeout(() => {
              this.loading = false
              resolve({
                items,
                total
              })
            }, 1000)
          })
          .catch( error => {
            if (error.message.toUpperCase() == 'NETWORK ERROR')
            {
              // this.$router.push('/Login')            
              alert('Ha ocurrido un error al cargar los mensajes. Por favor, vuelva a intentarlo.')
            }
            else
            {
              alert('Ha ocurrido un error al cargar los mensajes. Por favor, vuelva a intentarlo.')
            }
            this.loading = false
          })
        })
      },
      GetItemClass: function (item) {
        return item.MsjLeido ? 'leido-style' : 'pte-lectura-style';
      }
  },
}
</script>
<style>
  .col_para {
    min-width: 250px;
  }
  .pte-lectura-style {
    font-weight: bold;
  }
  .leido-style {
    font-weight: normal;
  }  
</style>
