<template>
    <v-autocomplete
      v-model="value"
      cache-items
      dense
      :hide-selected="multiple"
      :items="itemsList"
      :search-input.sync="searchItem"
      open-on-clear
      item-value="MsjCatId"
      item-text="MsjCatNom"
      outlined
      no-data-text="Introduzca texto a buscar"
      :label="label"
      return-object
      ref="MsjCatFindCtrl"
      :readonly="readonly"
      :clearable="!readonly"
      :multiple="multiple"
      :menu-props="{'open-on-click': true}"
      @click="clickEvt"
      @click:append="clickAppendEvt"
      @input="inputEvt"
      @change="changeEvt"
      @blur="blurEvt"
    ></v-autocomplete>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  export default {
    computed:{
        ...mapState(['empId', 'PerId', 'urlRaiz'])
    },
    data: () => ({
      itemsList: [],
      searchItem: null,
    }),
    props: ['value', 'MsjCatIdParent', 'label', 'multiple', 'readonly', 'selectOnReadonly'],
    watch: {
      searchItem(val) {
        this.loadItems(val);
      },
      MsjCatIdParent() {
        this.$refs.MsjCatFindCtrl.cachedItems = [];
        this.loadItems('');
      }
    },
    methods: {
      loadItems(valFind) {
        var controllerParameters = {   
            Action: 'GET_DATA_LIST_PAGINATED',
            CampoBusqueda: 'MsjCatNom',
            ValorBuscar: valFind,
            NumRegsPag: 35,
            NumPag: 1,
            EmpId: this.empId,
            MsjCatIdParent: null
        };

        var valorActual = this.value;
        var AuthToken = localStorage.getItem('token');  
        axios({ method: "POST", "url": this.urlRaiz + "/api/MsjCat", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
        .then(result => {
            this.itemsList = [];

            result.data.EntsList.forEach(element => {
              var itemObject = { MsjCatId: element.MsjCatId, MsjCatNom: element.MsjCatNom }
              this.itemsList.push(itemObject);
            });

            if (valorActual != null) { 
              if (!Array.isArray(valorActual)) {  // es un objeto
                if (valorActual.MsjCatId != null) {
                  if (!this.itemsList.some(itm=>itm.MsjCatId === valorActual.MsjCatId)) {
                    this.itemsList.push(valorActual);
                  }
                }
              }
              else {
                valorActual.forEach(element => {  // es un array
                  if (!this.itemsList.some(itm=>itm.MsjCatId === element.MsjCatId)) {
                    this.itemsList.push(element);
                  }
                });
              }
            }
        });
      },
      manageMenu(checkMenu) {
        if (checkMenu) {
          if (!this.$refs.MsjCatFindCtrl.isMenuActive) {
            this.$refs.MsjCatFindCtrl.isMenuActive = true;
          }
          else {
            this.$refs.MsjCatFindCtrl.isMenuActive = false;
          }
        }
      },
      clickEvt() {
        const checkMenu = this.readonly && this.selectOnReadonly;
        this.manageMenu(checkMenu);
        this.$emit('click');
      },
      clickAppendEvt() {
        const checkMenu = !this.readonly || (this.readonly && this.selectOnReadonly);
        this.manageMenu(checkMenu);
        this.$refs.MsjCatFindCtrl.focus();
        this.$emit('click-append');
      },
      inputEvt() {
        this.$emit('input', this.value);
      },
      changeEvt() {
        this.$emit('change', this.value);
      },
      blurEvt() {
        this.$emit('blur', this.value);
      }
    },
    mounted() {
      this.itemsList = [];
      this.loadItems('');
    }
  }
</script>